<template>
  <div class="main-services" id="services">
    <div class="container">
      <div class="content-services">
        <h1 class="title">Nuestros servicios</h1>
        <div class="row">
          <div class="col-md-4" v-for="service in services" :key="service.id">
            <div class="card-service">
              <img :src="service.img" :alt="service.img" />
              <div class="info">
                <div class="content-text">
                  <p class="sub-title">{{ service.title }}</p>
                  <button @click="passData(service.id)">Ver más</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SERVICES } from "@/constants/services.js";

export default {
  name: "Services",
  data() {
    return { services: SERVICES };
  },
  methods: {
    passData(idService) {
      this.$router.push({ name: "Services", params: { id: idService } });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/index.scss";

.main-services {
  padding-top: 100px;
  @media (max-width: 768px) {
    padding-top: 60px;
  }
  .container {
    .content-services {
      background: $white;
      padding: 40px 30px 0;
      font-family: $lato;
      .title {
        text-align: center;
        margin-bottom: 80px;
        color: $dark;
        position: relative;
        @media (max-width: 768px) {
          margin-bottom: 60px;
        }
        &:after {
          content: "";
          position: absolute;
          left: 0;
          right: 0;
          top: 100%;
          margin-left: auto;
          margin-right: auto;
          margin-top: 10px;
          width: 100px;
          height: 4px;
          background: $yellow;
        }
      }
      .row {
        text-align: center;
        .card-service {
          width: 100%;
          height: 300px;
          border-radius: 0px;
          background: white;
          position: relative;
          display: flex;
          align-items: flex-end;
          transition: 0.4s ease-out;
          box-shadow: 0px 7px 10px rgba(black, 0.5);
          margin-bottom: 40px;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            position: absolute;
            top: 0;
            left: 0;
            border-radius: 0px;
          }
          .info {
            position: relative;
            z-index: 3;
            color: white;
            transition: 0.5s;
            text-align: center;
            width: 100%;
            background-color: rgb(56 142 225 / 80%);
            padding: 10px;
            .content-text {
              text-align: center;
              p {
                letter-spacing: 1px;
                font-size: 18px;
                font-weight: bold;
                text-align: center;
                font-family: $lato;
              }
              button {
                padding: 0.6rem;
                outline: none;
                border: 1px solid $white;
                border-radius: 3px;
                background: transparent;
                color: black;
                font-weight: bold;
                cursor: pointer;
                transition: 0.4s ease;
                color: white;
                font-family: $lato;
                &:hover {
                  background: $white;
                  color: $blue;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>