import uno from "@/assets/img/services/instalaciones/1.jpg"
import dos from "@/assets/img/services/instalaciones/2.jpg"
import tres from "@/assets/img/services/instalaciones/3.webp"
import cuatro from "@/assets/img/services/instalaciones/4.webp"

export const INSTALACIONES = {
    id: 4,
    title: "Instalaciones",
    text: "",
    services: [
        {
            id: 1,
            title: 'Instalaciones eléctricas',
            description: '',
            tasks: [
                {
                    id: 1,
                    info: 'Instalaciones y reparaciones eléctricas en general.'
                },
                {
                    id: 2,
                    info: 'Salidas y cajas para vías de comunicación y señalización.'
                },
                {
                    id: 3,
                    info: 'Entubados, cableados e instalaciones de luminarias.'
                },
                {
                    id: 4,
                    info: 'Servicio de puesta a tierra.'
                }

            ],
            images: [
                {
                    id: 1,
                    img: uno
                },
                {
                    id: 2,
                    img: dos
                },
            ]
        },
        {
            id: 2,
            title: 'Redes y Cableado Estructurado',
            description: '',
            tasks: [
                {
                    id: 1,
                    info: 'Soluciones de cableado estructurado para voz, datos y video.'
                },
                {
                    id: 2,
                    info: 'Diagnóstico de instalación, reorganización de cableados en cuartos de equipos y site de telecomunicaciones.'
                },
                {
                    id: 3,
                    info: 'Diseño de cableado estructurado para centros de datos.'
                },
                {
                    id: 4,
                    info: 'Pruebas de instalación con equipos de certificación FLUKE Networks para cableados de UTP y fibra óptica.'
                }

            ],
            images: [
                {
                    id: 1,
                    img: tres
                },
                {
                    id: 2,
                    img: cuatro
                }
            ]
        }
    ],
    img: uno
}