<template>
  <div>
    <div class="main-projects">
      <div class="projects-background">
        <h1 class="projects-title">Nuestros Proyectos</h1>
      </div>
      <div class="container">
        <div
          class="content-projects"
          v-for="projects in projects"
          :key="projects.id"
        >
          <div class="content-title">
            <h1 class="title">{{ projects.name }}</h1>
          </div>
          <div class="row content-slider">
            <vueper-slides
              :autoplay="true"
              class="no-shadow"
              :visible-slides="3"
              slide-multiple
              :gap="1"
              :dragging-distance="200"
              :bullets="false"
              :breakpoints="{
                768: {
                  visibleSlides: 2,
                  slideMultiple: 2,
                  arrows: false,
                  fixedHeight: '400px',
                },
                425: {
                  visibleSlides: 1,
                  slideMultiple: 1,
                  arrows: false,
                  fixedHeight: '400px',
                },
              }"
            >
              <vueper-slide
                v-for="(images, i) in projects.images"
                :key="i"
                :title="i.toString()"
                :image="images.img"
              >
                <template #content>
                  <div class="project-card">
                    <div class="card-title">
                      <h2>{{ images.service }}</h2>
                    </div>
                  </div>
                </template>
              </vueper-slide>
            </vueper-slides>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";
import { PROJECTS } from "@/constants/projects.js";

export default {
  name: "Projects",
  components: {
    VueperSlides,
    VueperSlide,
  },
  data() {
    return {
      projects: PROJECTS,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/index.scss";

.main-projects {
  margin-bottom: 200px;
  .projects-background {
    background: linear-gradient(to top, #00000040, #00000040),
      url("../assets/img/projects/portada.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom;
    height: 600px;
    position: relative;
    &:before {
      background-color: rgb(0 0 0 / 30%);
      content: "";
      display: block;
      height: 100%;
      position: absolute;
      width: 100%;
    }
    .projects-title {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: $white;
      font-family: $lato;
      @media (max-width: 768px) {
        text-align: center;
      }
    }
  }
  .container {
    .content-projects {
      margin: 100px 0;
      .content-title {
        border-left: 5px solid $blue;
        padding-left: 5px;
        margin-bottom: 40px;
        font-family: $lato;
        .title {
          color: $blue;
          margin-bottom: 0px;
        }
      }
      .content-slider {
        .vueperslides__inner {
          height: 500px;
          .project-card {
            width: 100%;
            height: 100%;
            position: relative;
            .img-content {
              height: 100%;
              object-fit: cover;
            }
            .card-title {
              position: absolute;
              bottom: 0;
              background-color: rgb(56 142 222 / 80%);
              width: 100%;
              margin-bottom: 0;
              padding: 20px;
              text-align: center;
              font-family: $lato;
              h2 {
                font-size: 20px;
                color: $white;
                margin-bottom: 0;
                text-transform: uppercase;
              }
            }
          }
        }
      }
    }
  }
}
</style>
