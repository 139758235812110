import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/landing/Home.vue";
import Project from "../views/landing/Projects.vue";
import Services from "../views/landing/Services.vue";

// import Login from "../views/admin/Login.vue";
// import Admin from "../views/admin/Login.vue";
import adminRoutes from './admin'
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/proyectos",
    name: "Projects",
    component: Project,
  },
  {
    path: "/servicios/:id",
    name: "Services",
    component: Services,
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/landing/About.vue"),
  },
  ...adminRoutes

];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    window.scrollTo(0, 0);
  }
});

export default router;
