<template>
  <div>
    <Header />
    <Projects />
    <Contact />
    <Footer />
    <Whatsapp />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Projects from "@/components/Projects.vue";
import Contact from "@/components/Contact.vue";
import Footer from "@/components/Footer.vue";
import Whatsapp from "@/components/Whatsapp.vue";

export default {
  name: "Home",
  components: {
    Header,
    Projects,
    Contact,
    Footer,
    Whatsapp,
  },
};
</script>
