<template>
  <div class="main-banner" id="banner">
    <div class="content-banner">
      <div class="content-banner-slider">
        <vueper-slides
          :autoplay="true"
          :arrows="false"
          fixed-height="95vh"
          fade
        >
          <vueper-slide
            v-for="(slide, i) in banner"
            :key="i"
            :image="slide.img"
            :title="slide.title"
            :style="'background-color: ' + ['#ff5252', '#42b983'][i % 2]"
          >
            <template #content>
              <div class="vueperslide__content-wrapper">
                <div class="shadow"></div>
                <div class="content-banner-text">
                  <h2 class="banner-title">{{ slide.title }}</h2>
                  <a class="banner-button" :href="slide.click" target="__blank"
                    >Ver Brochure</a
                  >
                </div>
              </div>
            </template>
          </vueper-slide>
        </vueper-slides>
      </div>
    </div>
  </div>
</template>

<script>
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";
import { BANNER } from "@/constants/banner.js";

export default {
  name: "Banner",
  components: {
    VueperSlides,
    VueperSlide,
  },
  data() {
    return {
      banner: BANNER,
      listaDatos: [],
    };
  },
  methods: {
    getLista() {
      const idSheets = "1GwP9riAZ8n98e84SnpgneCk_AB9Ko-1x9eOcCtDvZM4";
      const api_key = "AIzaSyBOnxyItQ7-oDN44N7GuQT8pHRjDskVFVg";
      // const values = "A2:AZ100";

      fetch(
        "https://content-sheets.googleapis.com/v4/spreadsheets/" +
          idSheets +
          "/values/A2:AZ100?access_token=" +
          api_key +
          "&key=" +
          api_key
      )
        .then((lista) => {
          return lista.json();
        })
        .then((valores) => {
          this.listaDatos = valores.values;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  created() {
    this.getLista();
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/index.scss";
.shadow {
  background: linear-gradient(#00000040, #00000040);
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
}
.main-banner {
  min-height: 0vh;
  height: 100%;
  .content-banner {
    .content-banner-slider {
      .content-banner-text {
        z-index: 9;
        .banner-title {
          padding-top: 100px;
          text-align: center;
          color: $white;
          margin-bottom: 40px;
          text-transform: uppercase;
          font-size: 36px;
          font-family: $lato;
          font-weight: 700;
        }
        .banner-button {
          background-color: $blue;
          border: 2px solid $blue;
          color: $white;
          font-size: 20px;
          padding: 10px 30px;
          text-transform: uppercase;
          border-radius: 5px;
          text-decoration: none;
          font-family: $noto;
        }
      }
    }
  }
}
</style>