import uno from "@/assets/img/services/sistema/1.jpg"
import dos from "@/assets/img/services/sistema/2.jpg"
import tres from "@/assets/img/services/sistema/3.jpg"
import cuatro from "@/assets/img/services/sistema/4.jpg"
import cinco from "@/assets/img/services/sistema/5.jpg"

export const SISTEMA = {
    id: 9,
    title: "Sistema de Pozo a Tierra",
    text: "",
    services: [
        {
            id: 1,
            title: 'Sistema de Pozo a Tierra',
            description: '',
            tasks: [
                {
                    id: 1,
                    info: 'Protocolo firmado por un profesional, documento válido para trámites INDECI y municipalidades.'
                },
                {
                    id: 2,
                    info: 'Pruebas de resistividad para dosificar el nivel de tratamiento.'
                },
                {
                    id: 3,
                    info: 'Cambio de conectores.'
                },
                {
                    id: 4,
                    info: 'Aplicación de bentonita con sal industrial.'
                },
                {
                    id: 5,
                    info: 'Limpieza de cables sulfatados.'
                },

                {
                    id: 6,
                    info: 'Medición de los valores de resistencia.'
                },

            ],
            images: [
                {
                    id: 1,
                    img: uno
                },
                {
                    id: 2,
                    img: dos
                },
                {
                    id: 3,
                    img: tres
                },
                {
                    id: 4,
                    img: cuatro
                }
            ]
        },

    ],
    img: cinco
}