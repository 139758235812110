<template>
  <div class="main-about" id="about">
    <div class="container">
      <div class="content-about" v-animate-css="'fadeInUp'">
        <h1 class="title">¿Quiénes <span>somos</span>?</h1>
        <p class="text">
          MEEDU PROJECTS S.A.C. una empresa que tiene como objetivo brindar una
          variedad de servicios y soporte técnico especializado a todos nuestros
          clientes, tenemos a los mejores profesionales en innovación que se
          encuentran constantemente en capacitación para ofrecer los mejores
          métodos y técnicas en cada especialidad, ya que nuestro objetivo es
          cumplir con los estándares de calidad.
        </p>
        <div class="row">
          <div class="col-md-6">
            <img src="@/assets/img/values/mision.svg" class="img-about" />
            <p class="sub-title">Misión</p>
            <p class="text">
              Entregar el mejor servicio a nuestros clientes, en concordancia
              con el sistema de gestión integrado, fijando metas y objetivos que
              permitan prestar servicios de calidad en la ejecución y
              oportunidad, estableciendo como base fundamental de nuestra
              compañía el crecimiento sostenible, la permanencia en el mercado y
              las relaciones con nuestro equipo de trabajo.
            </p>
          </div>
          <div class="col-md-6">
            <img src="@/assets/img/values/vision.svg" class="img-about" />
            <p class="sub-title">Visión</p>
            <p class="text">
              Ser una empresa líder en el desarrollo de proyectos de minería,
              construcción de obras civiles, instalación de geosintéticos,
              arriendo de maquinarias y servicios de transporte, consolidando
              nuestro liderazgo a través de la mejora constante de nuestros
              procesos, además del sólido compromiso y responsabilidad con
              nuestros clientes y colaboradores.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "About",
  data() {
    return {
      fadeInUp: {
        clases: "rebote",
        retraso: 1000,
        duración: 2000,
      },
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/index.scss";

.main-about {
  padding: 80px 0;
  @media (max-width: 768px) {
    padding: 60px 0;
  }
  .container {
    .content-about {
      text-align: center;
      .title {
        text-align: center;
        margin-bottom: 80px;
        color: $dark;
        position: relative;
        font-family: $lato;
        &:after {
          content: "";
          position: absolute;
          left: 0;
          right: 0;
          top: 100%;
          margin-left: auto;
          margin-right: auto;
          margin-top: 10px;
          width: 100px;
          height: 4px;
          background: $yellow;
        }
        span {
          color: $blue;
        }
        @media (max-width: 768px) {
          margin-bottom: 60px;
        }
      }
      .img-about {
        width: 200px;
        margin-bottom: -20px;
      }
      .text {
        text-align: center;
        margin-bottom: 80px;
        padding: 0 40px;
        font-family: $noto;
        color: black;
        @media (max-width: 768px) {
          margin-bottom: 20px;
        }
      }
      .sub-title {
        font-size: 20px;
        color: $blue;
        text-transform: uppercase;
        font-weight: bold;
        font-family: $lato;
      }
    }
  }
}
</style>