
import { MOVISTAR } from "./projectsImages/movistar"
import { MR_SHAO } from "./projectsImages/mrshao"
import { PARDOS } from "./projectsImages/pardos"
import { PLANET_CHICKEN } from "./projectsImages/planet"

export const PROJECTS = [
    PARDOS,
    MR_SHAO,
    PLANET_CHICKEN,
    MOVISTAR
]