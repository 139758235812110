import uno from "@/assets/img/services/obras/1.jpeg"
import dos from "@/assets/img/services/obras/2.jpg"
import tres from "@/assets/img/services/obras/3.jpeg"
import cuatro from "@/assets/img/services/obras/4.jpeg"

export const OBRAS = {
    id: 1,
    title: "Obras civíles",
    text: "",
    services: [
        {
            id: 1,
            title: 'Obras Civiles',
            description: '',
            tasks: [
                {
                    id: 1,
                    info: 'Remodelaciones, ampliaciones y divisiones.'
                },
                {
                    id: 2,
                    info: 'Reparaciones de áreas ensalitradas, rajaduras, tarrajeos y vaciados.                        '
                },
                {
                    id: 3,
                    info: 'Cielo ras, acústicos en baldosas y dry-wall.'
                },
                {
                    id: 4,
                    info: 'Mantenimiento de tejas andinas, membranas asfálticas, reparación de picaduras y orificios.'
                },
                {
                    id: 5,
                    info: 'Resanado de estructuras dañadas o agrietados por humedad o el transcurrir del tiempo.'
                },
                {
                    id: 6,
                    info: 'Albañilería, paredes, vaciado de pisos, encofrados, columnas, vigas y tarrajeos.'
                },
                {
                    id: 7,
                    info: 'Acabados, enchapes, mayólica, porcelanato, vinílicos, piedra laja y mármoles.'
                },
                {
                    id: 8,
                    info: 'Enchapes e instalaciones sanitarias y losas.'
                },
                {
                    id: 9,
                    info: 'Acabados en pisos pulidos y demipulidos.'
                },
                {
                    id: 10,
                    info: 'Carpeta asfálticas y confitillo para las playas de estacionamiento.'
                },
                {
                    id: 11,
                    info: 'Trabajos sobre techos, coberturas en calaminas, TR4 y fibraforte.'
                },
                {
                    id: 12,
                    info: 'Limpieza general al término de la obra.'
                }

            ],
            images: [
                {
                    id: 1,
                    img: uno
                },
                {
                    id: 2,
                    img: dos
                },
                {
                    id: 3,
                    img: tres
                },
                {
                    id: 4,
                    img: cuatro
                }
            ]
        }
    ],
    img: uno
}