<template>
  <div class="main-footer">
    <div class="container">
      <div class="row">
        <div class="col-md-4 content-row">
          <h5 class="footer-title">Navega</h5>
          <div class="row">
            <div class="col-md-4">
              <p><a href="/#banner">Inicio</a></p>
              <p><a href="/#about">Nosotros</a></p>
            </div>
            <div class="col-md-4">
              <p><a href="/#services">Servicios</a></p>
              <p><a href="/#customers">Clientes</a></p>
            </div>
            <div class="col-md-4">
              <p>
                <router-link :to="{ name: 'Projects' }">Proyectos</router-link>
              </p>
              <p><a href="/#contact">Contacto</a></p>
            </div>
          </div>
        </div>
        <div class="col-md-4 content-row">
          <h5 class="footer-title">Contáctanos</h5>
          <p>
            <span><i class="fa fa-phone" aria-hidden="true"></i></span> (+51)
            933-234-798
          </p>
          <p>
            <span><i class="fa fa-phone" aria-hidden="true"></i></span> (+51)
            980 -668- 464
          </p>
          <p>
            <span><i class="fa fa-map-marker" aria-hidden="true"></i></span> CAL
            A URB. Victor Raul Haya de La To Mz A. R1 Lote 10 Independencia –
            Lima
          </p>
        </div>
        <div class="col-md-4 content-row">
          <h5 class="footer-title">Nuestras redes sociales</h5>
          <div class="content-social-media">
            <a href="https://www.facebook.com/Meeduprojects/" target="_blank"
              ><i class="fa fa-facebook-square" aria-hidden="true"></i
            ></a>
            <a
              href="https://www.linkedin.com/in/meedu-projects-sac-86738022b"
              target="_blank"
            >
              <i class="fa fa-linkedin" aria-hidden="true"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/index.scss";

.main-footer {
  padding: 80px 0;
  background-color: $yellow;
  @media (max-width: 768px) {
    padding: 40px 20px;
  }
  .container {
    .row {
      .content-row {
        @media (max-width: 768px) {
          text-align: center;
          margin-bottom: 40px;
        }
        .footer-title {
          margin-bottom: 30px;
          color: $white;
          position: relative;
          font-family: $lato;
          &:after {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            top: 100%;
            margin: 3px 0;
            width: 80px;
            height: 4px;
            background: $blue;
            @media (max-width: 768px) {
              left: calc(50% - 40px);
            }
          }
        }
        p {
          font-weight: 200;
          color: $white;
          font-family: $noto;
          span {
            font-weight: bold;
            margin-right: 5px;
          }
          a {
            color: $white;
            text-decoration: none;
          }
        }
      }
      .content-social-media {
        display: flex;
        @media (max-width: 768px) {
          justify-content: center;
        }
        i {
          margin-right: 10px;
          font-size: 28px;
          color: $white;
        }
      }
    }
  }
}
</style>
