import uno from "@/assets/img/services/vidrios/1.jpg"
import dos from "@/assets/img/services/vidrios/2.jpg"
import tres from "@/assets/img/services/vidrios/3.jpg"
import cuatro from "@/assets/img/services/vidrios/4.jpg"

export const VIDRIOS = {
    id: 7,
    title: "Vidrios",
    text: "",
    services: [
        {
            id: 1,
            title: 'Vidrios',
            description: '',
            tasks: [
                {
                    id: 1,
                    info: 'Instalación de puertas y mamparas de aluminio.'
                },
                {
                    id: 2,
                    info: 'Instalación y mamparas de cristal templado de 8 y 10mm con todos los accesorios de aluminio pesado.'
                },
                {
                    id: 3,
                    info: 'Cerraduras de pomo interiores y puerta principal.'
                },
                {
                    id: 4,
                    info: 'Puertas de ducha en cristal templado 6 y 8mm con Tec-Vidrio importado (cromado y dorado).'
                },
                {
                    id: 5,
                    info: 'Puertas de ducha en acrílico y aluminio color natural.'
                },
                {
                    id: 6,
                    info: 'Cristales para escritorios, mesas, repisas y todo tipo de muebles.'
                },
                {
                    id: 7,
                    info: 'Espejos biselados de 0.5, 1.0, 1.5, 2.0 y 2.5 cm.'
                },
                {
                    id: 8,
                    info: 'Rejas de seguridad de aluminio.'
                },
                {
                    id: 9,
                    info: 'Pasamanos y barandas de aluminio y acero inoxidable.'
                },
                {
                    id: 10,
                    info: 'Pasamanos y barandas con cristal templado.'
                },
                {
                    id: 11,
                    info: 'Cierra de puertas de bra.'
                },
                {
                    id: 12,
                    info: 'Limpieza general al término de la obra.'
                }

            ],
            images: [
                {
                    id: 1,
                    img: uno
                },
                {
                    id: 2,
                    img: dos
                },
                {
                    id: 3,
                    img: tres
                },
                {
                    id: 4,
                    img: cuatro
                }
            ]
        },

    ],
    img: uno
}