<template>
  <div class="main-whatsapp">
    <a
      href="https://api.whatsapp.com/send?phone=+51933234798&text=Hola%2C%20quisiera%20tener%20mas%20informaci%C3%B3n"
      target="_blank"
      ><i class="fa fa-whatsapp my-float" style="margin: 15px"></i
    ></a>
  </div>
</template>
<script>
export default {
  name: "Whatsapp",
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/index.scss";
.main-whatsapp {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
  cursor: pointer;
  a {
    text-decoration: none;
    color: $white;
  }
}
</style>