import uno from "@/assets/img/projects/planetchicken/1.jpg"
import dos from "@/assets/img/projects/planetchicken/2.jpeg"
import tres from "@/assets/img/projects/planetchicken/3.jpg"
import cuatro from "@/assets/img/projects/planetchicken/4.jpg"
import cinco from "@/assets/img/projects/planetchicken/5.jpg"
import seis from "@/assets/img/projects/planetchicken/6.jpg"

export const PLANET_CHICKEN = {
    id: 3,
    name: 'Planet Chicken',
    project: 'Proyecto x',
    images: [
        {
            id: 1,
            img: uno,
            service: 'Cambio de mayólicas'
        },
        {
            id: 2,
            img: dos,
            service: 'Desatoro de tubería'
        },
        {
            id: 3,
            img: tres,
            service: 'Fabricación estructura metálicas'
        },
        {
            id: 4,
            img: cuatro,
            service: 'Instalación de estructura de drywall'
        },
        {
            id: 5,
            img: cinco,
            service: 'Instalación estructura metálicass'
        },
        {
            id: 6,
            img: seis,
            service: 'Limpieza de fachada'
        },
    ]
}