<template>
  <div class="main-login">
    <div class="container">
      <div class="card-login">
        <img src="@/assets/logo.png" width="300px" />
        <form @submit.prevent="login">
          <div class="col-12 mb-2">
            <label for="user">Usuario</label>
            <input
              type="text"
              class="form-control"
              id="user"
              v-model="data.user"
            />
          </div>
          <div class="col-12 mb-2">
            <label for="password">Contraseña</label>
            <input
              type="password"
              class="form-control"
              id="password"
              v-model="data.password"
            />
          </div>
          <button class="btn btn-blue">Ingresar</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      data: {
        user: null,
        password: null,
      },
    };
  },
  methods: {
    login() {
      console.log("mensaje", this.data);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/index.scss";

.main-login {
  min-height: 100vh;
  padding: 100px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: $yellow;
  .container {
    .card-login {
      background: $blue;
      max-width: 500px;
      margin: 0 auto;
      padding: 30px 20px;
      img {
        display: flex;
        margin: 0 auto;
      }
      form {
        margin-top: 40px;
        .btn-blue {
          margin-top: 40px;
          width: 100%;
          background: $yellow;
          color: white;
        }
      }
    }
  }
}
</style>