<template>
  <div>
    <Header />
    <ServiceDetail />
    <Contact />
    <Footer />
  </div>
</template>
<script>
import Header from "@/components/Header.vue";
import ServiceDetail from "@/components/ServiceDetail.vue";
import Footer from "../../components/Footer.vue";
import Contact from "../../components/Contact.vue";

export default {
  name: "Services",
  components: {
    Header,
    ServiceDetail,
    Footer,
    Contact,
  },
};
Footer;
</script>
