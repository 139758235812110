import uno from "@/assets/img/projects/movistar/1.jpg"
import dos from "@/assets/img/projects/movistar/2.jpg"

export const MOVISTAR = {
    id: 4,
    name: 'Movistar',
    project: 'Proyecto x',
    images: [
        {
            id: 1,
            img: uno,
            service: 'Mantenimiento de puerta enrrolable'
        },
        {
            id: 2,
            img: dos,
            service: 'Mantenimiento de sistema eléctrico y engranaje'
        },
    ]
}