import { OBRAS } from "./servicesImages/obras"
import { MANTENIMIENTO } from "./servicesImages/mantenimiento"
import { PINTURA } from "./servicesImages/pintura"
import { INSTALACIONES } from "./servicesImages/instalaciones"
import { GASFITERIA } from "./servicesImages/gasfiteria"
import { CERRAJERIA } from "./servicesImages/cerrajeria"
import { VIDRIOS } from "./servicesImages/vidrios"
import { REFRIGERACION } from "./servicesImages/refrigeracion"
import { SISTEMA } from "./servicesImages/sistema"

export const SERVICES = [
    OBRAS,
    MANTENIMIENTO,
    PINTURA,
    INSTALACIONES,
    GASFITERIA,
    CERRAJERIA,
    VIDRIOS,
    REFRIGERACION,
    SISTEMA
]