<template>
  <div class="main-contact" id="contact">
    <div class="container">
      <div class="content-contact">
        <div class="card-contact">
          <div class="row">
            <div class="col-md-12">
              <div class="card-info">
                <h1 class="title">Información</h1>
                <p class="sub-title">
                  Si tienes una consulta no dudes en escribirnos o mandarnos un
                  mensaje.
                </p>
                <div class="content-info">
                  <i class="fa fa-phone" aria-hidden="true"></i>
                  <p>
                    933-234-798
                    <br />
                    980 -668- 464
                  </p>
                </div>
                <div class="content-info">
                  <i class="fa fa-envelope" aria-hidden="true"></i>
                  <p>meedu.projects@gmail.com</p>
                </div>
                <div class="content-info">
                  <i class="fa fa-map-marker" aria-hidden="true"></i>
                  <p>
                    CAL A URB. Victor Raul Haya de La To Mz A. R1 Lote 10<br />
                    Independencia - Lima
                  </p>
                </div>
              </div>
            </div>
            <!-- <div class="col-md-6 d-none">
              <div class="card-form">
                <h1 class="title">Solicitar cotización</h1>
                <form @submit.prevent="sendMessage">
                  <div class="row">
                    <div class="col-6 mb-2">
                      <label for="name">Nombre o Empresa *</label>
                      <input
                        type="text"
                        class="form-control"
                        id="name"
                        v-model="data.name"
                      />
                    </div>
                    <div class="col-6 mb-2">
                      <label for="name">Ruc (opcional)</label>
                      <input
                        type="text"
                        class="form-control"
                        id="ruc"
                        v-model="data.ruc"
                      />
                    </div>
                    <div class="col-6 mb-2">
                      <label for="phone">Teléfono *</label>
                      <input
                        type="text"
                        class="form-control"
                        id="phone"
                        v-model="data.phone"
                      />
                    </div>
                    <div class="col-6 mb-2">
                      <label for="email">Correo *</label>
                      <input
                        type="text"
                        class="form-control"
                        id="email"
                        v-model="data.email"
                      />
                    </div>
                    <div class="col-12">
                      <label for="message">Mensaje *</label>
                      <textarea
                        class="form-control"
                        id="message"
                        rows="3"
                        v-model="data.message"
                      ></textarea>
                    </div>
                  </div>
                  <button class="button-yellow" type="submit">Enviar</button>
                </form>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Contact",
  data() {
    return {
      data: {
        name: null,
        ruc: null,
        phone: null,
        email: null,
        message: null,
      },
    };
  },
  methods: {
    sendMessage() {},
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/index.scss";

.main-contact {
  display: flex;
  padding: 100px 0;
  align-items: center;
  background: linear-gradient(rgba(56, 141, 221, 0.5), rgba(56, 141, 221, 0.5)),
    url("../assets/img/servicios-generales.png");
  background-attachment: fixed;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  .container {
    .content-contact {
      .card-contact {
        background: $white;
        margin: 0 auto;
        max-width: 600px;
        .card-form {
          height: 100%;
          background: $yellow;
          padding: 40px;
          position: relative;
          @media (max-width: 768px) {
            padding: 40px 20px;
          }
          &:after {
            content: "";
            width: 0px;
            height: 0px;
            position: absolute;
            border-left: 30px solid transparent;
            border-right: 30px solid $yellow;
            border-top: 30px solid $yellow;
            border-bottom: 30px solid transparent;
            left: -60px;
            top: 60px;
            @media (max-width: 768px) {
              display: none;
            }
          }
          .title {
            text-transform: uppercase;
            font-size: 24px;
            font-weight: 700;
            margin-bottom: 40px;
            color: $white;
            font-family: $lato;
          }
          label {
            color: white;
            font-family: $noto;
            @media (max-width: 768px) {
              font-size: 14px;
            }
          }
          .button-yellow {
            margin-top: 60px;
            background-color: $blue;
            border: 2px solid $blue;
            color: $white;
            font-size: 18px;
            padding: 5px 40px;
            border-radius: 5px;
            width: 100%;
          }
        }
      }
      .card-info {
        padding: 40px;
        .title {
          text-transform: uppercase;
          font-size: 24px;
          color: $dark;
          font-weight: 700;
          margin-bottom: 20px;
          font-family: $lato;
        }
        .sub-title {
          font-size: 18px;
          margin-bottom: 30px;
          font-family: $noto;
        }
        .content-info {
          display: flex;
          align-items: center;
          margin-bottom: 40px;
          font-family: $noto;
          i {
            font-size: 24px;
            margin-right: 15px;
          }
        }
      }
    }
  }
}
</style>