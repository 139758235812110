<template>
  <div class="main-header">
    <div class="container">
      <div class="content-header">
        <div class="content-logo">
          <router-link :to="{ name: 'Home' }">
            <img src="@/assets/logo.jpg" alt="logo" />
          </router-link>
        </div>
        <div class="content-routes">
          <a href="/#banner">Inicio</a>
          <a href="/#about">Nosotros</a>
          <a href="/#services">Servicios</a>
          <a href="/#customers">Clientes</a>
          <router-link :to="{ name: 'Projects' }">Proyectos</router-link>
          <a href="/#contact">Contacto</a>
        </div>
        <div class="content-menu-mobile" @click="showMenu">
          <i class="fa fa-bars" aria-hidden="true"></i>
        </div>
        <div class="content-menu" :class="menu && 'show-menu'">
          <div class="menu-mobile">
            <div class="menu-mobile-header" @click="showMenu">
              <i class="fa fa-times" aria-hidden="true"></i>
            </div>
            <div class="menu-mobile-content">
              <a @click="showMenu" href="/#banner">Inicio</a>
              <a @click="showMenu" href="/#about">Nosotros</a>
              <a @click="showMenu" href="/#services">Servicios</a>
              <a @click="showMenu" href="/#customers">Clientes</a>
              <router-link :to="{ name: 'Projects' }" @click="showMenu"
                >Proyectos</router-link
              >
              <a @click="showMenu" href="/#contact">Contacto</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {
      menu: false,
    };
  },
  methods: {
    showMenu() {
      this.menu = !this.menu;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/index.scss";

.main-header {
  background-color: $white;
  padding: 15px 5px;
  width: 100%;
  position: fixed;
  z-index: 99;
  box-shadow: 0px 0px 10px 3px rgb(0 0 0 / 50%);
  .content-menu-mobile {
    display: none;
    @media (max-width: 900px) {
      display: block;
    }
  }
  .content-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .content-logo {
      img {
        width: 280px;
      }
    }
    .content-routes {
      font-family: $lato;
      font-weight: bold;
      @media (max-width: 900px) {
        display: none;
      }
      a {
        margin: 0 15px;
        color: $dark;
        font-size: 16px;
        font-weight: 700;
        text-transform: capitalize;
        cursor: pointer;
        text-decoration: none;
        &:hover {
          color: $blue;
        }
      }
    }
    .menu-mobile {
      .menu-mobile-header {
        display: flex;
        padding: 30px;
        justify-content: flex-end;
        height: 72px;
      }
    }
    .content-menu {
      position: absolute;
      height: 100vh;
      background: white;
      width: 0%;
      top: 0;
      right: 0;
      transition: width 1s;
      .menu-mobile-content {
        display: grid;
        text-align: center;
        a {
          text-decoration: none;
          padding: 20px 0;
          font-size: 18px;
          color: $dark;
          font-family: $lato;
        }
      }
    }
    .show-menu {
      width: 100%;
      transition: width 1s;
    }
  }
}
</style>