import uno from "@/assets/img/services/gasfiteria/1.jpg"
import dos from "@/assets/img/services/gasfiteria/2.jpg"

export const GASFITERIA = {
    id: 5,
    title: "Gasfitería y Fontanería",
    text: "",
    services: [
        {
            id: 1,
            title: 'Gasfitería y Fontanería',
            description: '',
            tasks: [
                {
                    id: 1,
                    info: 'Instalaciones y cambios de tuberías de redes de agua y desagüe.'
                }, {
                    id: 2,
                    info: 'Reparación y desatoros con cables y bombas de succión.'
                }, {
                    id: 3,
                    info: 'Instalaciones y cambios de sanitarios y griferías.'
                }, {
                    id: 4,
                    info: 'Instalación y mantenimiento de fluxómetros, tiners y mezcladoras de duchas.'
                }
            ],
            images: [
                {
                    id: 1,
                    img: uno
                },
                {
                    id: 2,
                    img: dos
                }
            ]
        },
    ],
    img: uno
}