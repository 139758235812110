<template>
  <div class="main-service-detail">
    <div class="img-background">
      <img class="img-service" :src="this.model.img" alt="img-background" />
      <h1 class="title-service">{{ this.model.title }}</h1>
    </div>
    <div class="navbar-section">
      <div class="container d-flex">
        <p>
          <a href="/#services"> Servicios </a> >
          {{ this.model.title }}
        </p>
      </div>
    </div>
    <div class="container p-100">
      <div class="content-title">
        <h1 class="mt-5">Nuestros Servicios</h1>
        <span> {{ this.model.title }}</span>
      </div>
      <div
        class="row"
        v-for="services in this.model.services"
        :key="services.id"
      >
        <div class="col-md-6">
          <h1 class="title-task">{{ services.title }}</h1>
          <ul v-for="tasks in services.tasks" :key="tasks.id">
            <li>{{ tasks.info }}</li>
          </ul>
        </div>
        <div class="col-md-6">
          <div class="row content-img">
            <div
              class="col-md-6"
              v-for="image in services.images"
              :key="image.id"
            >
              <img :src="image.img" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SERVICES } from "@/constants/services.js";
export default {
  name: "ServiceDetail",
  data() {
    return {
      serviceId: this.$route.params.id,
      services: SERVICES,
      model: {},
    };
  },
  methods: {
    getById() {
      for (let i = 0; i < this.services.length; i++) {
        if (SERVICES[i].id == this.serviceId) {
          this.model = SERVICES[i];
        }
      }
    },
  },
  created() {
    this.getById();
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/index.scss";
.main-service-detail {
  .img-background {
    position: relative;
    &:before {
      background-color: rgb(0 0 0 / 30%);
      content: "";
      display: block;
      height: 100%;
      position: absolute;
      width: 100%;
    }
    .img-service {
      width: 100%;
      height: 400px;
      object-fit: cover;
    }
    .title-service {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: $white;
      font-family: $lato;
      @media (max-width: 768px) {
        text-align: center;
      }
    }
  }
  .navbar-section {
    padding: 20px 0;
    color: $white;
    background-color: $blue;
    font-size: 18px;
    box-shadow: 0px 0px 10px 3px rgb(0 0 0 / 50%);
    .container {
      p {
        margin-bottom: 0;
        a {
          color: $white;
          text-decoration: none;
          font-family: $noto;
        }
      }
    }
  }
  .p-100 {
    padding-bottom: 100px;
  }
  .container {
    .content-title {
      border-left: 5px solid $blue;
      padding-left: 5px;
      margin-bottom: 40px;
      font-family: $lato;
      h1 {
        color: $blue;
        margin-bottom: 0px;
        font-family: $lato;
      }
      span {
        font-size: 20px;
        font-weight: bold;
      }
    }
    .row {
      margin-bottom: 60px;
      .title-task {
        font-size: 24px;
        color: $blue;
        margin-bottom: 30px;
      }
      ul {
        font-family: $noto;
      }
      .content-img {
        img {
          width: 100%;
          height: 250px;
          object-fit: cover;
          margin-bottom: 24px;
        }
      }
    }
  }
}
</style>