import uno from "@/assets/img/services/mantenimiento/1.jpg"
import runo from "@/assets/img/services/mantenimiento/reparacion/1.jpeg"
import rdos from "@/assets/img/services/mantenimiento/reparacion/2.jpg"
import rtres from "@/assets/img/services/mantenimiento/reparacion/3.jpg"
import rcuatro from "@/assets/img/services/mantenimiento/reparacion/4.jpeg"
import iuno from "@/assets/img/services/mantenimiento/instalaciones/1.jpg"
import idos from "@/assets/img/services/mantenimiento/instalaciones/2.jpg"

export const MANTENIMIENTO = {
    id: 2,
    title: 'Mantenimiento',
    text: '',
    services: [
        {
            id: 1,
            title: 'Mantenimiento y Reparación',
            description: '',
            tasks: [
                {
                    id: 1,
                    info: 'Revisión y pintado de cableado.'
                },
                {
                    id: 2,
                    info: 'Mantenimiento de postes.'
                },
                {
                    id: 3,
                    info: 'Instalaciones y cambios de aisladores.'
                },
                {
                    id: 4,
                    info: 'Revisión de la batería, sirena y línea a tierra.'
                },
                {
                    id: 5,
                    info: 'Tableros eléctricos.'
                },
                {
                    id: 6,
                    info: 'Instrumentación y actuadores'
                },
                {
                    id: 7,
                    info: 'Variadores de velocidad y arrancadores suaves.'
                },
                {
                    id: 8,
                    info: 'Sistema de bombeo.'
                },
            ],
            images: [
                {
                    id: 1,
                    img: runo
                },
                {
                    id: 2,
                    img: rdos
                },
                {
                    id: 3,
                    img: rtres
                },
                {
                    id: 4,
                    img: rcuatro
                }
            ]
        },
        {
            id: 2,
            title: 'Mantenimiento de Instalaciones',
            description: '',
            tasks: [
                {
                    id: 1,
                    info: 'Mantenimiento de tableros y cuchillas.'
                },
                {
                    id: 2,
                    info: 'Llaves estabilizadoras, toma corrientes, interruptores y bombas de agua.'
                },
                {
                    id: 3,
                    info: 'Mantenimiento e instalación de cercos eléctricos.'
                },
                {
                    id: 4,
                    info: 'Mantenimiento de Puestas a tierra.'
                },
            ],
            images: [
                {
                    id: 1,
                    img: iuno
                },
                {
                    id: 2,
                    img: idos
                }
            ]
        }
    ],
    img: uno
}