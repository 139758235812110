import uno from "@/assets/img/projects/mrshao/1.jpg"
import dos from "@/assets/img/projects/mrshao/2.jpg"
import tres from "@/assets/img/projects/mrshao/3.jpeg"
import cuatro from "@/assets/img/projects/mrshao/4.jpg"
import cinco from "@/assets/img/projects/mrshao/5.jpg"
import seis from "@/assets/img/projects/mrshao/6.jpg"
import siete from "@/assets/img/projects/mrshao/7.jpg"
import ocho from "@/assets/img/projects/mrshao/8.jpg"

export const MR_SHAO = {
    id: 2,
    name: 'MR SHAO',
    project: 'Proyecto x',
    images: [
        {
            id: 1,
            img: uno,
            service: 'Cambio de locetas'
        },
        {
            id: 2,
            img: dos,
            service: 'Instalación puerta de acero inox'
        },
        {
            id: 3,
            img: tres,
            service: 'Mantenimiento de logos'
        },
        {
            id: 4,
            img: cuatro,
            service: 'Mantenimiento de pintura'
        },
        {
            id: 5,
            img: cinco,
            service: 'Mantenimiento de sillas'
        },
        {
            id: 6,
            img: seis,
            service: 'Pintado exterior'
        },
        {
            id: 7,
            img: siete,
            service: 'Pintado exterior de fachada'
        },
        {
            id: 8,
            img: ocho,
            service: 'Pintado interior de local'
        },

    ]

}