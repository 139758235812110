import uno from "@/assets/img/services/pintura/1.jpg"
import muno from "@/assets/img/services/pintura/maderas/1.webp"
import mdos from "@/assets/img/services/pintura/maderas/2.webp"
import mtres from "@/assets/img/services/pintura/maderas/3.jpeg"
import mcuatro from "@/assets/img/services/pintura/maderas/4.jpg"
import iuno from "@/assets/img/services/pintura/interior/1.png"
import idos from "@/assets/img/services/pintura/interior/2.jpeg"
import inuno from "@/assets/img/services/pintura/industrial/1.jpeg"
import indos from "@/assets/img/services/pintura/industrial/2.jpg"
import euno from "@/assets/img/services/pintura/estructuras/1.webp"
import edos from "@/assets/img/services/pintura/estructuras/2.jpg"

export const PINTURA = {
    id: 3,
    title: 'Pintura',
    text: '',
    services: [
        {
            id: 1,
            title: 'Pintura de Maderas',
            description: '',
            tasks: [
                {
                    id: 1,
                    info: 'Materiales: Barniz, Gloss, Laca, DD.'
                },
                {
                    id: 2,
                    info: 'Pintado de puertas y portones.'
                },
                {
                    id: 3,
                    info: 'Pintado de pasamanos y barandas.'
                },
                {
                    id: 4,
                    info: 'Pintado de pisos.'
                },
                {
                    id: 5,
                    info: 'Pintado de cercos.'
                },
                {
                    id: 6,
                    info: 'Muebles de madera.'
                },
                {
                    id: 7,
                    info: 'Pintado al duco.'
                },
                {
                    id: 8,
                    info: 'Cobertura.'
                },
            ],
            images: [
                {
                    id: 1,
                    img: muno
                },
                {
                    id: 2,
                    img: mdos
                },
                {
                    id: 3,
                    img: mtres
                },
                {
                    id: 4,
                    img: mcuatro
                }
            ]
        },
        {
            id: 2,
            title: 'Pintura de interiores y exteriores',
            description: '',
            tasks: [
                {
                    id: 1,
                    info: 'Materiales: Látex y sanitarios.'
                },
                {
                    id: 2,
                    info: 'Finos acabados y empastados de interiores y exteriores de instalaciones.'
                },
                {
                    id: 3,
                    info: 'Pintado de interiores y exteriores de paredes y techos.'
                },
                {
                    id: 4,
                    info: 'Pintado de fachadas externas de edificios.'
                },
                {
                    id: 5,
                    info: 'Encarchado decorativo, granulado con o sin brillo.'
                },
                {
                    id: 6,
                    info: 'Tratamiento con materiales antisalitres y anticorrosivos.'
                },

            ],
            images: [
                {
                    id: 1,
                    img: iuno
                },
                {
                    id: 2,
                    img: idos
                },
            ]
        },
        {
            id: 3,
            title: 'Pintura Industrial',
            description: '',
            tasks: [
                {
                    id: 1,
                    info: 'Materiales: Epóxido de alta temperatura y poliuretano.'
                },
                {
                    id: 2,
                    info: 'Pintado de máquinas industriales.'
                },
                {
                    id: 3,
                    info: 'Pintado de marmitas de altas temperaturas.'
                },
                {
                    id: 4,
                    info: 'Pintado de tuberías galvanizadas, de acero y PVC.'
                },
                {
                    id: 5,
                    info: 'Estructuras de aluminio.'
                },
            ],
            images: [
                {
                    id: 1,
                    img: inuno
                },
                {
                    id: 2,
                    img: indos
                },

            ]
        },
        {
            id: 4,
            title: 'Pintura de Estructuras Metálicas',
            description: '',
            tasks: [
                {
                    id: 1,
                    info: 'Materiales: Pintura sintética'
                },
                {
                    id: 2,
                    info: 'Pintado de puertas y portones de fierro y eléctricos.'
                },
                {
                    id: 3,
                    info: 'Pintado de pasamanos, barandas, ventanas y escalera caracol.'
                },
                {
                    id: 4,
                    info: 'Pintado de rejas y cercos.'
                }
            ],
            images: [
                {
                    id: 1,
                    img: euno
                },
                {
                    id: 2,
                    img: edos
                },
            ]
        }
    ],
    img: uno
}