<template>
  <div class="main-values">
    <div class="container">
      <div class="content-values">
        <h1 class="title">Nuestros valores institucionales</h1>
        <div class="row">
          <div class="col-md-6">
            <img src="@/assets/img/values/confianza.svg" />
            <p class="text">
              <span class="sub-title">CONFIANZA</span>
              <br />
              Brindar la seguridad del trabajo realizado en beneficio de
              nuestros clientes, velando por lo intereses de los trabajadores y
              la comunidad.
            </p>
          </div>
          <div class="col-md-6">
            <img src="@/assets/img/values/excelencia.svg" />
            <p class="text">
              <span class="sub-title">EXCELENCIA</span><br />
              Orientar la gestión al logro de los objetivos en la oportunidad
              requerida y al menor costo para la empresa y la sociedad en su
              conjunto, aplicando criterios técnicos para la mejora continua de
              los procesos.
            </p>
          </div>
          <div class="col-md-6">
            <img src="@/assets/img/values/etica.svg" />
            <p class="text">
              <span class="sub-title">ÉTICA </span> <br />Actuar respetando las
              normas, los principios y las opiniones tanto internas como
              externas.
            </p>
          </div>

          <div class="col-md-6">
            <img src="@/assets/img/values/solidaridad.svg" />
            <p class="text">
              <span class="sub-title">COMPROMISO</span><br />
              Actuar identificados con la empresa, sus principios, funciones y
              objetivos.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Values",
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/index.scss";

.main-values {
  padding: 80px 0;
  @media (max-width: 768px) {
    padding: 60px 0;
  }
  .container {
    .content-values {
      text-align: center;
      .title {
        text-align: center;
        margin-bottom: 80px;
        color: $dark;
        position: relative;
        font-family: $lato;
        &:after {
          content: "";
          position: absolute;
          left: 0;
          right: 0;
          top: 100%;
          margin-left: auto;
          margin-right: auto;
          margin-top: 10px;
          width: 100px;
          height: 4px;
          background: $yellow;
        }
        @media (max-width: 768px) {
          margin-bottom: 60px;
        }
      }
      .row {
        .col-md-6 {
          margin-bottom: 20px;
          @media (max-width: 768px) {
            margin-bottom: 0px;
          }

          img {
            width: 200px;
          }
        }
      }
      .text {
        text-align: center;
        margin-bottom: 20px;
        font-size: 18px;
        padding: 0 60px;
        font-family: $noto;
        @media (max-width: 768px) {
          padding: 0 40px;
          margin-top: -40px;
        }
        .sub-title {
          font-size: 20px;
          color: $blue;
          text-transform: uppercase;
          font-weight: bold;
          font-family: $lato;
        }
      }
    }
  }
}
</style>