<template>
  <div>
    <Header />
    <Banner />
    <About />
    <Values />
    <Services />
    <Customers />
    <Contact />
    <Map />
    <Footer />
    <Whatsapp />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Banner from "@/components/Banner.vue";
import About from "@/components/About.vue";
import Values from "@/components/Values.vue";
import Services from "@/components/Services.vue";
import Customers from "@/components/Customers.vue";
import Contact from "@/components/Contact.vue";
import Map from "@/components/Map.vue";
import Footer from "@/components/Footer.vue";
import Whatsapp from "@/components/Whatsapp.vue";

export default {
  name: "Home",
  components: {
    Header,
    Banner,
    About,
    Values,
    Services,
    Customers,
    Contact,
    Map,
    Footer,
    Whatsapp,
  },
};
</script>
