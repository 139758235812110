<template>
  <div class="main-customers" id="customers">
    <div class="container">
      <div class="content-customers">
        <h1 class="title">Nuestros clientes</h1>
        <div class="content-carousel">
          <div
            class="row text-center justify-content-center align-items-center"
          >
            <div class="col">
              <img src="@/assets/img/customers/pardos.jpg" class="brand-img" />
            </div>
            <div class="col">
              <img src="@/assets/img/customers/mrshao.jpg" class="brand-img" />
            </div>
            <div class="col">
              <img src="@/assets/img/customers/planet.png" class="brand-img" />
            </div>
            <div class="col">
              <img src="@/assets/img/customers/sic.png" class="brand-img" />
            </div>
            <div class="col">
              <img
                src="@/assets/img/customers/movistar.png"
                class="brand-img"
              />
            </div>
            <div class="col">
              <img src="@/assets/img/customers/tdm.jpg" class="brand-img" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Customers",
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/index.scss";

.main-customers {
  padding: 100px 0;
  .container {
    .content-customers {
      .title {
        text-align: center;
        margin-bottom: 80px;
        color: $dark;
        position: relative;
        font-family: $lato;
        &:after {
          content: "";
          position: absolute;
          left: 0;
          right: 0;
          top: 100%;
          margin-left: auto;
          margin-right: auto;
          margin-top: 10px;
          width: 100px;
          height: 4px;
          background: $yellow;
        }
        @media (max-width: 768px) {
          margin-bottom: 60px;
        }
      }
      .text {
        color: $dark;
        font-size: 18px;
        margin-bottom: 140px;
        text-align: center;
      }
      .brand-img {
        max-width: 150px;
        width: 100%;
        @media (max-width: 768px) {
          width: 150px;
        }
      }
    }
  }
}
</style>
