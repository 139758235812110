import Login from "../views/admin/Login.vue";
import Admin from "../views/admin/Admin.vue";
export default [
    {
        path: "/login",
        name: "Login",
        component: Login,
    },
    {
        path: "/admin",
        name: "Admin",
        component: Admin,
    },
]