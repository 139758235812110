var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"main-projects"},[_vm._m(0),_c('div',{staticClass:"container"},_vm._l((_vm.projects),function(projects){return _c('div',{key:projects.id,staticClass:"content-projects"},[_c('div',{staticClass:"content-title"},[_c('h1',{staticClass:"title"},[_vm._v(_vm._s(projects.name))])]),_c('div',{staticClass:"row content-slider"},[_c('vueper-slides',{staticClass:"no-shadow",attrs:{"autoplay":true,"visible-slides":3,"slide-multiple":"","gap":1,"dragging-distance":200,"bullets":false,"breakpoints":{
              768: {
                visibleSlides: 2,
                slideMultiple: 2,
                arrows: false,
                fixedHeight: '400px',
              },
              425: {
                visibleSlides: 1,
                slideMultiple: 1,
                arrows: false,
                fixedHeight: '400px',
              },
            }}},_vm._l((projects.images),function(images,i){return _c('vueper-slide',{key:i,attrs:{"title":i.toString(),"image":images.img},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',{staticClass:"project-card"},[_c('div',{staticClass:"card-title"},[_c('h2',[_vm._v(_vm._s(images.service))])])])]},proxy:true}],null,true)})}),1)],1)])}),0)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"projects-background"},[_c('h1',{staticClass:"projects-title"},[_vm._v("Nuestros Proyectos")])])}]

export { render, staticRenderFns }