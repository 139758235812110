import uno from "@/assets/img/services/refrigeracion/1.webp"
import dos from "@/assets/img/services/refrigeracion/2.jpg"

export const REFRIGERACION = {
    id: 8,
    title: "Sistema de Refrigeración",
    text: "",
    services: [
        {
            id: 1,
            title: 'Sistema de Refrigeración',
            description: '',
            tasks: [
                {
                    id: 1,
                    info: 'Instalaciones de equipos de refrigeración y aire acondicionado.'
                },
                {
                    id: 2,
                    info: 'Mantenimiento preventivo.'
                },
                {
                    id: 3,
                    info: 'Asistencia técnica y diagnósticos acertados.'
                },
                {
                    id: 4,
                    info: 'Reparación de sistemas y refrigeración.'
                },

            ],
            images: [
                {
                    id: 1,
                    img: uno
                },
                {
                    id: 2,
                    img: dos
                }
            ]
        },

    ],
    img: uno
}