import uno from "@/assets/img/projects/pardos/1.jpg"
import dos from "@/assets/img/projects/pardos/2.jpg"
import tres from "@/assets/img/projects/pardos/3.jpeg"
import cuatro from "@/assets/img/projects/pardos/4.jpg"
import cinco from "@/assets/img/projects/pardos/5.jpg"
import seis from "@/assets/img/projects/pardos/6.jpg"
import siete from "@/assets/img/projects/pardos/7.jpg"
import ocho from "@/assets/img/projects/pardos/8.jpg"
import nueve from "@/assets/img/projects/pardos/9.jpg"
import diez from "@/assets/img/projects/pardos/10.jpg"
import once from "@/assets/img/projects/pardos/11.jpg"
import doce from "@/assets/img/projects/pardos/12.jpg"
import trece from "@/assets/img/projects/pardos/13.jpg"
import catorce from "@/assets/img/projects/pardos/14.jpg"
import quince from "@/assets/img/projects/pardos/15.jpg"
import diesiseis from "@/assets/img/projects/pardos/16.jpg"
import diesisiete from "@/assets/img/projects/pardos/17.jpg"


export const PARDOS = {
    id: 1,
    name: 'Pardos Chicken',
    project: 'Proyecto x',
    images: [
        {
            id: 1,
            img: uno,
            service: 'Cambio de luminarias'
        },
        {
            id: 2,
            img: dos,
            service: 'Fabricación e instalación de puerta'
        },
        {
            id: 3,
            img: tres,
            service: 'Instalación acrílico área de cajas'
        },
        {
            id: 4,
            img: cuatro,
            service: 'Instalación de sumidero de acero inox'
        },
        {
            id: 5,
            img: cinco,
            service: 'Instalación de mayólicas'
        },
        {
            id: 6,
            img: seis,
            service: 'Limpieza de lunas'
        },
        {
            id: 7,
            img: siete,
            service: 'Limpieza de vidrios'
        },
        {
            id: 8,
            img: ocho,
            service: 'Limpieza fachada'
        },
        {
            id: 9,
            img: nueve,
            service: 'Limpieza general'
        },
        {
            id: 10,
            img: diez,
            service: 'Limpieza mampara'
        },
        {
            id: 11,
            img: once,
            service: 'Mantenimiento de puerta metálica'
        },
        {
            id: 12,
            img: doce,
            service: 'Mantenimiento de horno parrilla'
        },
        {
            id: 13,
            img: trece,
            service: 'Mantenimiento de logo'
        },
        {
            id: 14,
            img: catorce,
            service: 'Mantenimiento de pintura exterior'
        },
        {
            id: 15,
            img: quince,
            service: 'Mantenimiento horno brasa'
        },
        {
            id: 16,
            img: diesiseis,
            service: 'Pintado de interior de local'
        },
        {
            id: 17,
            img: diesisiete,
            service: 'Pintado de puerta'
        }
    ]

}