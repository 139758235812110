import uno from "@/assets/img/services/cerrajeria/1.jpeg";
import dos from "@/assets/img/services/cerrajeria/2.jpg";

export const CERRAJERIA = {
    id: 6,
    title: "Cerrajería",
    text: "",
    services: [
        {
            id: 1,
            title: 'Cerrajería',
            description: '',
            tasks: [
                {
                    id: 1,
                    info: 'Instalación de todo tipo de cerraduras (chapas de perilla, de barra y de piso).'
                },
                {
                    id: 2,
                    info: 'Cerraduras de pomo, pico de loro, cantol, cierra puertas y antipático.'
                },
                {
                    id: 3,
                    info: 'Instalación y mantenimiento de chapas eléctricas.'
                },
                {
                    id: 4,
                    info: 'Mantenimiento preventivo y amaestramiento.'
                },
                {
                    id: 5,
                    info: 'Reparación de cerraduras y mecanismos de escritorios credenzas y archiveros.'
                },
                {
                    id: 6,
                    info: 'Duplicado de llaves multipuntos.'
                },
                {
                    id: 7,
                    info: 'Mantenimiento preventivo en cajas fuertes.'
                }

            ],
            images: [
                {
                    id: 1,
                    img: uno
                },
                {
                    id: 2,
                    img: dos
                }
            ]
        },

    ],
    img: uno
}