import electrico from "@/assets/img/banner/electrico.jpeg"
import estructuras from "@/assets/img/banner/estructuras.jpg"
// import obra from "@/assets/img/banner/obra.jpeg"
import pintura from "@/assets/img/banner/pintura.jpg"
import diseño from "@/assets/img/banner/diseño.png"

export const BANNER = [
    {
        id: 1,
        img: pintura,
        title: "Cambia tu estilo y ponle color.",
        button: "hola",
        click: "/pdf/brochure.pdf",
    },
    {
        id: 2,
        img: diseño,
        title: " El mejor diseño para tus edificaciones.",
        button: "hola",
        click: "/pdf/brochure.pdf",
    },
    {
        id: 3,
        img: estructuras,
        title: " Estructuras con resistencia y de calidad.",
        button: "hola",
        click: "/pdf/brochure.pdf",
    },
    {
        id: 4,
        img: electrico,
        title: "No entendemos la electricidad, lo usamos.",
        button: "hola",
        click: "/pdf/brochure.pdf",
    },

]