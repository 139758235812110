<template>
  <div>
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3902.9357974346217!2d-77.0569722!3d-11.9789444!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x27fc8b5004371760!2zMTHCsDU4JzQ0LjIiUyA3N8KwMDMnMjUuMSJX!5e0!3m2!1ses!2spe!4v1661471934846!5m2!1ses!2spe"
      width="100%"
      height="400px"
      style="border: 0"
      allowfullscreen=""
      loading="lazy"
    ></iframe>
  </div>
</template>

<script>
export default {
  name: "Map",
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/index.scss";
</style>