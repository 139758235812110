<template>
  <div class="main-admin">
    <nav class="navbar navbar-light bg-dark">
      <div class="container">
        <a class="navbar-brand">Navbar</a>
        <form class="form-inline">
          <button class="btn btn-outline-success my-2 my-sm-0" type="submit">
            Search
          </button>
        </form>
      </div>
    </nav>
    <div class="container">
      <h1 class="m-4">Registros</h1>
      <div class="overflow-auto">
        <p class="mt-3">Current Page: {{ currentPage }}</p>

        <b-table
          id="my-table"
          :items="items"
          :per-page="perPage"
          :current-page="currentPage"
          small
        ></b-table>
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="my-table"
        ></b-pagination>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Admin",
  data() {
    return {
      perPage: 3,
      currentPage: 1,
      items: [
        { id: 1, Nombre: "Fred", email: "Flintstone", mensaje: "hola" },
        { id: 2, Nombre: "Wilma", email: "Flintstone", mensaje: "hola" },
        { id: 3, Nombre: "Barney", email: "Rubble", mensaje: "hola" },
        { id: 4, Nombre: "Betty", email: "Rubble", mensaje: "hola" },
        { id: 5, Nombre: "Pebbles", email: "Flintstone", mensaje: "hola" },
        { id: 6, Nombre: "Bamm Bamm", email: "Rubble", mensaje: "hola" },
        { id: 7, Nombre: "The Great", email: "Gazzoo", mensaje: "hola" },
        { id: 8, Nombre: "Rockhead", email: "Slate", mensaje: "hola" },
        { id: 9, Nombre: "Pearl", email: "Slaghoople", mensaje: "hola" },
      ],
    };
  },
  computed: {
    rows() {
      return this.items.length;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/index.scss";
.main-admin {
  .navbar {
    .container {
      .navbar-brand {
        color: $white !important;
      }
    }
  }
}
</style>